import {Controller} from "stimulus"

export default class extends Controller {
    static values = {
        loading: Boolean,
        autoLoadPages: Boolean,
        recommendationsColumnId: String,
        recommendationsTableId: String
    }

    static get targets() {
        return ["wrapper"]
    }

    connect() {
        this.wrapper = $(this.wrapperTarget)
        this.formWrapper = $("#filterrific_filter")
        this.recommendationsList = this.wrapper.find(".recommendations-column-list")
        this.loadMoreRecommendationsButton = this.wrapper.find('.load-more-recommendations-button')
        this.lastReloading = null

        if(this.loadingValue) {
            this._load_recommendations()
        } else {
            this._find_last_contact_attempt()
        }

        this.wrapper.on('reloadLastContactAttempt', this._find_last_contact_attempt)
        this.wrapper.on('reloadRecommendations', this._reload_recommendations)
    }

    disconnect() {
        this.wrapper.unbind('reloadLastContactAttempt')
        this.wrapper.unbind('reloadRecommendations')
    }

    _find_last_contact_attempt = () => {
        let allLastContactAttemptTimes = this.recommendationsList.children().map(function() { return $(this).data('lastContactAttemptTime') } )
        let lastContactAttemptTime = Math.max.apply(Math, allLastContactAttemptTimes)

        this.recommendationsList.children().each(function () {
            if($(this).data('lastContactAttemptTime') === lastContactAttemptTime) {
                $(this).find('.recommendation-table-item-nav').addClass('recommendation-table-item-last-contact-attempt')
                $(this).find('.recommendation-table-item-nav').prop('title', 'W tej kolumnie to polecenie ma ostatnią próbę kontaktu')
                $(this).find('.recommendation-table-item').addClass('recommendation-table-item-last-contact-attempt')
            } else {
                $(this).find('.recommendation-table-item-nav').removeClass('recommendation-table-item-last-contact-attempt')
                $(this).find('.recommendation-table-item-nav').prop('title', '')
                $(this).find('.recommendation-table-item').removeClass('recommendation-table-item-last-contact-attempt')
            }
        })
    }

    _can_reload = () => {
        let now = new Date();
        if (this.lastReloading != null) {
            console.log('Last reloaded ', now - this.lastReloading, 'ago')
        }
        return this.lastReloading == null || (now - this.lastReloading) > 1000
    }

    _reload_recommendations = () => {
        if(this._can_reload()) {
            this.lastReloading = new Date();

            console.log('Reload column starting...')
            console.log('Current scroll position is ', window.scrollY)
            this.wrapper.data('beforeReloadingScrollY', window.scrollY)
            if(!this.loadingValue) {
                if(!this.autoLoadPagesValue) {
                    console.log('Fetching column recommendations')
                    this._load_recommendations()
                } else {
                    console.log('Resetting column...')
                    this._reset_recommendations()
                    this.loadingValue = false
                    this.loadMoreRecommendationsButton.trigger('reload')
                }
            }
        } else {
            console.log('Column already reloading')
        }
    }

    _reset_recommendations = () => {
        this.recommendationsList.children().remove()
        this.wrapper.find('.loading-spinner').show()
    }

    _load_recommendations = () => {
        this.loadingValue = true;
        this._reset_recommendations()
        Rails.ajax({
            url: window.location.origin + '/recommendations_tables/' + this.recommendationsTableIdValue + '/load_recommendations/' + this.recommendationsColumnIdValue + '?' + this.formWrapper.serialize(),
            type: 'get',
            success: this._show_recommendations
        })
    }

    _show_recommendations = (recommendations) => {
        if(this.loadingValue) {
            this.recommendationsList.append(recommendations.body.innerHTML)
            this.wrapper.find('.loading-spinner').hide()
            this._find_last_contact_attempt();
            this.loadingValue = false;
        }
    }

    fetch_loading_value = () => {
        return this.loadingValue;
    }
}
